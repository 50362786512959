/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRpc } from "ycc-api/src/service/Rpc";
import { getBlocksConsensusTxs, getBlocksConsensusTxsCount, getBlocksUnConsensusTxs, getBlocksUnConsensusTxsCount } from '../service/api'
import Vue from "vue";
import { getBlockOverviewAndRelativeHeight } from "ycc-api/dist/cmjs/service/blockDetail";
import { message } from "ant-design-vue";
import { tradeAccuracy } from "@/utils/common";
import { getConsensusList } from "@/utils/consensus";
import {
  iConsensusNodeTablListItem,
  iCustomizedGetTxByHashesRetTxItem,
} from "@/types/blockDetail";
import { yccApi } from "@/service/api";
const Rpc = getRpc(yccApi);
export default Vue.extend({
  data() {
    return {
      searchValue: "",
      focusedTab: "txRecordTab",
      txRecordFilterValue: "allTx",
      consensusNodeFilterValue: "allNode",
      consensusNodeTableList: [] as iConsensusNodeTablListItem[],
      loadingConsensusNodeTable: false,
      overview: {
        txCount: 0,
        blockTime: 0,
        hash: "", // 区块哈希
        stateHash: "", //状态哈希
        txHash: "", // 默克尔根
        lastBlockHeight: NaN,
        nextBlockHeight: NaN,
      },
      loadingOverview: false,
      loadingRelativeNumber: false,
      loadingTable: false,
      allTrades: [] as iCustomizedGetTxByHashesRetTxItem[],
      txHashes: [] as string[],
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isSuccess(execer: any, ty: any) {
      return !tradeAccuracy(execer, ty);
    },
    async fetchTradeFromTxHashes() {
      this.loadingTable = true;
      const height = Number(this.$route.query.height)
      const txc = await getBlocksUnConsensusTxsCount(height)
      this.pages.total = txc.result
      const txs = await getBlocksUnConsensusTxs(height, this.pages.currentPage, this.pages.pageSize)
      this.allTrades = txs.result
      this.loadingTable = false
    },
    // 共识交易
    async getBlocksConsensusTxs() {
      this.loadingTable = true
      const height = Number(this.$route.query.height)
      const txc = await getBlocksConsensusTxsCount(height)
      this.pages.total = txc.result
      const txs = await getBlocksConsensusTxs(height, this.pages.currentPage, this.pages.pageSize)
      let obj = {} as any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      txs.result.forEach((i: any) => {
        i.voter_addr.forEach((item: string) => {
          // i.voter_detail[item]++
          if (!obj[item]) obj[item] = 1
          else obj[item]++
        })
        i.voter_detail = obj
        obj = {}
      })

      this.consensusNodeTableList = txs.result
      this.loadingTable = false
    },
    tabChange(v: any) {
      this.focusedTab = v
      if (v == 'txRecordTab') {
        this.fetchTradeFromTxHashes()
      } else this.getBlocksConsensusTxs()
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkGroup(arr: iCustomizedGetTxByHashesRetTxItem[]) {
      arr.map((current, index) => {
        if (current.tx.groupCount > 1) {
          current.tradeG = 1; //0-非交易组，1-交易组，3-交易组末条, 2-交易组首条
          if (current.tx.fee !== 0) {
            current.tradeG = 2;
          } else {
            if (index < arr.length) {
              current.tradeG = (arr[index].tx as any).next ? 1 : 3;
            } else {
              current.tradeG = 3;
            }
          }
        } else {
          current.tradeG = 0;
        }
      });
      return arr;
    },
    clickSearch(v: string) {
      console.log(v, " show search value");
    },
    setSearchValue(v: string) {
      this.searchValue = v;
    },
    setFocusedTab(v: string) {
      this.focusedTab = v;
    },
    async fetchBlockOverviewAndRelativeBlockHeightData() {
      this.loadingOverview = true;
      this.loadingRelativeNumber = true;

      getBlockOverviewAndRelativeHeight("ycc", yccApi, {
        height: Number(this.$route.query.height),
      }).then((data) => {
        if (data) {
          const { relativeBlockHeight, overview } = data;
          overview.then((o: any) => {
            this.loadingOverview = false;
            this.overview = {
              ...this.overview,
              blockTime: o.blockTime,
              txCount: o.txCount,
              hash: o.hash,
              stateHash: o.stateHash,
              txHash: o.txHash,
            };
            this.txHashes = o.txHashes;
            this.fetchTradeFromTxHashes();
          });
          relativeBlockHeight.then((r: any) => {
            this.loadingRelativeNumber = false;
            this.overview = {
              ...this.overview,
              lastBlockHeight: r.lastBlockHeight,
              nextBlockHeight: r.nextBlockHeight,
            };
          });
        }
      });
    },
    async init() {
      this.fetchBlockOverviewAndRelativeBlockHeightData();
    },
    // 交易组模块
    groupCellName(val: any) {
      switch (val.row.tradeG) {
        case 0:
          return "";
        case 1:
          return "tx-middle";
        case 2:
          return "tx-start";
        case 3:
          return "tx-end";
        default:
          return "";
      }
    },
    pageChange(page: number) {
      this.pages.currentPage = page;
      if (this.focusedTab == 'txRecordTab') {
        this.fetchTradeFromTxHashes()
      } else this.getBlocksConsensusTxs()
    },
    sizeChange(size: number) {
      this.pages.pageSize = size;
      this.pages.currentPage = 1
      if (this.focusedTab == 'txRecordTab') {
        this.fetchTradeFromTxHashes()
      } else this.getBlocksConsensusTxs()
    },
  },
  computed: {
    tabList(): any[] {
      return [
        {
          name: this.$t("lang.trade.txRecord"),
          value: "txRecordTab",
        },
        {
          name: this.$t("lang.trade.consensusNode"),
          value: "consensusNodeTab",
        },
      ];
    },
    txRecordFilterOptionList() {
      return [
        { name: this.$t("lang.trade.title"), value: "allTx" },
        { name: this.$t("lang.txDetail.transfer"), value: "transfer" },
        { name: this.$t("lang.txDetail.mining"), value: "mining" },
        { name: this.$t("lang.txDetail.callContract"), value: "callContract" },
        { name: this.$t("lang.txDetail.synchronize"), value: "synchronize" },
      ];
    },
    consensusNodeFilterOptionList() {
      return [
        { name: this.$t("lang.block.allNode"), value: "allNode" },
        { name: this.$t("lang.block.packingNode"), value: "packingNode" },
        { name: this.$t("lang.block.votingNode"), value: "votingNode" },
      ];
    },
  },
  async mounted() {
    this.init();
  },
  watch: {
    "$route.query.height"() {
      this.pages = {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      };
      this.init();
    },
  },
});
